import { useMutation, useQueryClient } from "@tanstack/react-query";
import { backendApi, CreateCompanyRequest } from "../../backend/backend-api";
import { CacheKeys } from "../../common-ui/cacheKeys";

export const useCreateCompanyMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: CreateCompanyRequest) =>
      backendApi.createCompany(variables),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: CacheKeys.companies });
      onSuccess();
    },
  });
};
