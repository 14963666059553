import {
  INVOICE_DIRECTION_INBOUND,
  INVOICE_DIRECTION_OUTBOUND,
  InvoiceDirection,
} from "./types";

function stringContains(str: string, substr: string) {
  return str.toLowerCase().indexOf(substr) >= 0;
}

export const folderToInvoiceDirection = (
  parentFolderName: string,
): InvoiceDirection | null =>
  stringContains(parentFolderName, "purchase invoices")
    ? INVOICE_DIRECTION_INBOUND
    : stringContains(parentFolderName, "sales invoices")
      ? INVOICE_DIRECTION_OUTBOUND
      : null;

export const calculateInvoiceTotal = (
  netAmount: number | null,
  vatAmount: number | null,
  transportationCost: number | null,
) =>
  (netAmount ? netAmount : 0) +
  (vatAmount ? vatAmount : 0) +
  (transportationCost ? transportationCost : 0);
