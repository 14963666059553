import { DragHandle } from "@mui/icons-material";
import { PanelResizeHandle } from "react-resizable-panels";

export const PanelSeparator = () => (
  <PanelResizeHandle className="panel-resize-handle">
    <DragHandle
      className="rotate-90-deg panel-resize-handle__icon"
      fontSize="small"
    />
  </PanelResizeHandle>
);
