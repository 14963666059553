import React, { useCallback } from "react";
import { AttachmentMessage } from "./AttachmentMessage";
import { downloadFile } from "./file-utils";

export const NoPreviewAttachmentMessage = (props: {
  fileName: string;
  url: string;
}) => {
  const { fileName, url } = props;
  const download = useCallback(() => {
    if (!url) return;
    downloadFile(url, fileName);
  }, [fileName, url]);

  return (
    <AttachmentMessage
      fileName={fileName}
      buttonText="Download"
      message="We can’t preview this file"
      onButtonClick={download}
      dataTestPrefix="attachment-view__no-preview"
      isError={false}
    />
  );
};
