import { useEffect, useState } from "react";

// Msal imports
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionType,
  InteractionRequiredAuthError,
  AccountInfo,
} from "@azure/msal-browser";

// Sample app imports

// Material-ui imports
import Paper from "@mui/material/Paper";
import { backendTokenRequest } from "../auth/authConfig";
import { graphApi } from "../backend/graph-api";
import { GraphData, ProfileData } from "./ProfileData";
import { ErrorComponent } from "../common-ui/ErrorComponent";
import { Loading } from "../common-ui/Loading";

const ProfileContent = () => {
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState<null | GraphData>(null);

  useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      graphApi
        .getProfile()
        .then((response) => setGraphData(response))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...backendTokenRequest,
              account: instance.getActiveAccount() as AccountInfo,
            });
          }
        });
    }
  }, [inProgress, graphData, instance]);

  return (
    <Paper>{graphData ? <ProfileData graphData={graphData} /> : null}</Paper>
  );
};

export const Profile = () => {
  const authRequest = {
    ...backendTokenRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <ProfileContent />
    </MsalAuthenticationTemplate>
  );
};
