import NavBar from "./NavBar";
import { useIsAuthenticated } from "@azure/msal-react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { useShowLoginPopup } from "../auth/useShowLoginPopup";
import logoTextImage from "../assets/images/logo_text.png";

type Props = {
  children?: React.ReactNode;
};

const UnauthenticatedContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
`;

export const PageLayout: React.FC<Props> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { showLoginPopup } = useShowLoginPopup();
  return isAuthenticated ? (
    <>
      <NavBar />
      {children}
    </>
  ) : (
    <UnauthenticatedContainer>
      <img src={logoTextImage} alt="Pilargo" />
      <br />
      <Button onClick={showLoginPopup} variant="outlined">
        Login
      </Button>
    </UnauthenticatedContainer>
  );
};
