import React from "react";
import { InsertDriveFile } from "@mui/icons-material";
import Button from "@mui/material/Button";

export const AttachmentMessage = (props: {
  fileName: string;
  buttonText: string;
  message: string;
  onButtonClick: () => void;
  dataTestPrefix: string;
  isError: boolean;
}) => {
  const {
    fileName,
    buttonText,
    message,
    onButtonClick,
    dataTestPrefix,
    isError,
  } = props;

  return (
    <div
      className="attachments-view__message-container"
      data-test={`${dataTestPrefix}-container`}
    >
      <div className="attachments-view__message-wrapper">
        <InsertDriveFile fill="white" />
        <div
          className="attachments-view__message-file-name"
          data-test={`${dataTestPrefix}-file-name`}
        >
          {fileName}
        </div>
        <div
          className="attachments-view__message-text"
          data-test={`${dataTestPrefix}-message-text`}
          style={isError ? { color: "red" } : {}}
        >
          {message}
        </div>
        <div className="attachments-view__message-button-wrapper">
          <Button
            className="button-apply"
            data-test={`${dataTestPrefix}-button`}
            onClick={onButtonClick}
            style={{ width: "fit-content" }}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};
