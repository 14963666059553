import { callApi } from "./common";
import { appConfig } from "../config";

const GRAPH_API_BASE_URL = "https://graph.microsoft.com/v1.0";
const DRIVE_ID = appConfig.driveId;

type ResponseValue<T> = {
  value: T[];
};

export type GenericDriveItem = {
  id: string;
  name: string;
  type: "folder" | "file";
  parent: FolderDriveItem | null;
};

export type FileDriveItem = GenericDriveItem & {
  type: "file";
  mimeType: string | null;
};

export type FolderDriveItem = GenericDriveItem & {
  type: "folder";
  childCount: number;
};

export type DriveItem = FileDriveItem | FolderDriveItem;

export const graphApi = {
  getProfile: async () => callApi("graph", "GET", `${GRAPH_API_BASE_URL}/me`),
  getChildDriveItems: async (itemId: string): Promise<DriveItem[]> => {
    let response: ResponseValue<DriveItem> = await callApi(
      "graph",
      "GET",
      `${GRAPH_API_BASE_URL}/drives/${DRIVE_ID}/items/${itemId}/children`,
    );

    const allChildrenFiltered = (response.value as any[])
      .filter((x) => x.file || x.folder)
      .filter((x) => !x.name.startsWith("."));
    const parentName =
      allChildrenFiltered.length > 0
        ? allChildrenFiltered[0].parentReference.name
        : null;
    const parent: FolderDriveItem = {
      id: itemId,
      name: parentName,
      type: "folder",
      childCount: allChildrenFiltered.length,
      parent: null,
    };

    return allChildrenFiltered.map((x) =>
      x.file
        ? {
            id: x.id,
            name: x.name,
            type: "file",
            mimeType: x.file?.mimeType ?? null,
            parent,
          }
        : {
            id: x.id,
            name: x.name,
            type: "folder",
            childCount: x.folder.childCount,
            parent,
          },
    );
  },
  downloadFile: async (itemId: string): Promise<Blob> =>
    callApi(
      "graph",
      "GET",
      `${GRAPH_API_BASE_URL}/drives/${DRIVE_ID}/items/${itemId}/content`,
      "file",
    ),
  moveFile: async (fileDriveItem: FileDriveItem, newParentId: string) =>
    callApi(
      "graph",
      "PATCH",
      `${GRAPH_API_BASE_URL}/drives/${DRIVE_ID}/items/${fileDriveItem.id}`,
      "json",
      {
        parentReference: {
          id: newParentId,
        },
        name: fileDriveItem.name,
      },
    ),
};
