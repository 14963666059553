import React, { useEffect, useState } from "react";
import { CompanyDto } from "../backend/backend-api";
import { useFetchedCompanies } from "./useFetchedCompanies";
import { useLocalStorage, writeStorage } from "@rehooks/local-storage";

type CompanyContextProps = {
  getCurrentCompany: () => CompanyDto | null;
  isInitialized: () => boolean;
  setCurrentCompany: (company: CompanyDto) => void;
};

const CompanyContext = React.createContext<CompanyContextProps>({
  getCurrentCompany: () => null,
  isInitialized: () => false,
  setCurrentCompany: () => {},
});

type CurrentCompany = {
  id: string;
};

const CURRENT_COMPANY_KEY = "current-company-id";

function saveCurrentCompanyToLocalStorage(id: string) {
  writeStorage<CurrentCompany>(CURRENT_COMPANY_KEY, {
    id: id,
  });
}

export const CompanyProvider = (props: React.PropsWithChildren<{}>) => {
  const [company, setCompany] = useState<CompanyDto | null>(null);
  const { data: companies } = useFetchedCompanies();
  const [currentCompany] = useLocalStorage<CurrentCompany>(CURRENT_COMPANY_KEY);
  useEffect(() => {
    if (!companies || companies.length === 0) return;
    setCompany((prevState) => {
      if (prevState) return prevState;
      const savedCurrentCompany = companies.find(
        (x) => !!x.id && x.id === currentCompany?.id,
      );
      if (savedCurrentCompany) return savedCurrentCompany;

      const firstCompany = companies[0];
      saveCurrentCompanyToLocalStorage(firstCompany.id);
      return firstCompany;
    });
  }, [companies, currentCompany]);

  const companyContextProps: CompanyContextProps = {
    getCurrentCompany: () => company!!,
    isInitialized: () => !!company,
    setCurrentCompany: (company) => {
      saveCurrentCompanyToLocalStorage(company.id);
      setCompany(company);
    },
  };
  return <CompanyContext.Provider value={companyContextProps} {...props} />;
};

export const useCurrentCompany = (): { currentCompany: CompanyDto } => {
  const context = React.useContext(CompanyContext);
  return { currentCompany: context.getCurrentCompany()!! };
};

export const useCompanyContext = () => React.useContext(CompanyContext);
