import { FailedToDownloadAttachmentMessage } from "./FailedToDownloadAttachmentMessage";
import DocViewer from "@cyntler/react-doc-viewer";
import BMPRenderer from "@cyntler/react-doc-viewer/dist/cjs/renderers/bmp";
import CSVRenderer from "@cyntler/react-doc-viewer/dist/cjs/renderers/csv";
import GIFRenderer from "@cyntler/react-doc-viewer/dist/cjs/renderers/gif";
import HTMLRenderer from "@cyntler/react-doc-viewer/dist/cjs/renderers/html";
import ImageProxyRenderer from "@cyntler/react-doc-viewer/dist/cjs/renderers/image";
import JPGRenderer from "@cyntler/react-doc-viewer/dist/cjs/renderers/jpg";
import PDFRenderer from "@cyntler/react-doc-viewer/dist/cjs/renderers/pdf";
import PNGRenderer from "@cyntler/react-doc-viewer/dist/cjs/renderers/png";
import TIFFRenderer from "@cyntler/react-doc-viewer/dist/cjs/renderers/tiff";
import TXTRenderer from "@cyntler/react-doc-viewer/dist/cjs/renderers/txt";
import React, { useCallback, useMemo } from "react";
import { IConfig, IDocument } from "@cyntler/react-doc-viewer/dist/esm/models";
import { NoPreviewAttachmentMessage } from "./NoPreviewAttachmentMessage";
import _ from "lodash";
import { FileItem } from "./types";
import { useMemoCompare } from "../common-ui/hooks/useMemoCompare";
import { PanelSpinner } from "../common-ui/Spinner";
import { useAttachmentsContent } from "./hooks/useAttachmentsContent";

export type IndexedProgramAttachment = {
  attachment: FileItem;
  index: number;
};

const pluginRenderers = [
  BMPRenderer,
  CSVRenderer,
  GIFRenderer,
  HTMLRenderer,
  ImageProxyRenderer,
  JPGRenderer,
  PDFRenderer,
  PNGRenderer,
  TIFFRenderer,
  TXTRenderer,
];

export const AttachmentCarouselBody = (props: {
  attachments: FileItem[];
  currentAttachment: IndexedProgramAttachment;
}) => {
  const { attachments, currentAttachment } = props;

  const spinner = useMemo(() => <PanelSpinner />, []);
  const createSpinner = useCallback(() => spinner, [spinner]);

  const createNoAttachmentPreview = useCallback(
    (p: { document: IDocument | undefined; fileName: string }) => {
      return (
        <NoPreviewAttachmentMessage
          fileName={p.fileName}
          url={p.document?.uri ?? ""}
        />
      );
    },
    [],
  );

  const docViewerConfig = useMemo<IConfig>(
    () => ({
      header: { disableHeader: true },
      pdfVerticalScrollByDefault: true,
      loadingRenderer: {
        overrideComponent: createSpinner,
        showLoadingTimeout: 0,
      },
      noRenderer: {
        overrideComponent: createNoAttachmentPreview,
      },
    }),
    [createSpinner, createNoAttachmentPreview],
  );

  const queries = useAttachmentsContent(attachments, currentAttachment.index);
  const documents = useMemoCompare(
    queries.map((x) => ({
      uri: x.data?.url ?? "",
      fileName: x.data?.fileDriveItem.name ?? "",
    })),
    (a, b) => _.isEqual(a, b),
  );
  const query = queries[currentAttachment.index];

  if (!query || !currentAttachment) return <></>;

  return (
    <div className="attachments-view__main-container">
      <div
        className="attachments-view__doc-viewer-container"
        data-test="attachments-view__doc-viewer-container"
      >
        {query.status === "error" || query.isPaused ? (
          <FailedToDownloadAttachmentMessage
            fileName={currentAttachment.attachment.fileDriveItem.name}
            refetch={query.refetch}
          />
        ) : query.isLoading ? (
          <PanelSpinner />
        ) : (
          <DocViewer
            config={docViewerConfig}
            documents={documents}
            activeDocument={documents[currentAttachment.index]}
            pluginRenderers={pluginRenderers}
            className="attachments-view__doc-viewer"
          />
        )}
      </div>
    </div>
  );
};
