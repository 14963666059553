import { useState } from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useShowLoginPopup } from "./useShowLoginPopup";

export const LoginButton = () => {
  const { showLoginPopup } = useShowLoginPopup();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLogin = async () => {
    setAnchorEl(null);
    await showLoginPopup();
  };

  return (
    <div>
      <Button
        onClick={(event) => setAnchorEl(event.currentTarget)}
        color="inherit"
      >
        Login
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleLogin()} key="loginPopup">
          Login using Pilargo account
        </MenuItem>
      </Menu>
    </div>
  );
};
