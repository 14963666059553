import { Company } from "./types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CompanyForm } from "./CompanyForm";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useCreateCompanyMutation } from "./hooks/useCreateCompanyMutation";
import { useUpdateCompanyMutation } from "./hooks/useUpdateCompanyMutation";

export const CompanyDialog = (props: {
  company: Company;
  open: boolean;
  onClose: () => void;
}) => {
  const { company, open, onClose } = props;
  const [editedCompany, setEditedCompany] = useState(company);

  const { mutate: createCompany } = useCreateCompanyMutation(onClose);
  const { mutate: updateCompany } = useUpdateCompanyMutation();

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {company.id ? `Edit company - ${company.name}` : "New company"}
      </DialogTitle>
      <DialogContent>
        <CompanyForm
          company={editedCompany}
          onCompanyChanged={setEditedCompany}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (company.id) {
              updateCompany({ company: editedCompany });
            } else {
              createCompany(editedCompany);
            }
          }}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
