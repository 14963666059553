// Hook
import { useEffect, useRef } from "react";

export const usePrevious = <T>(next: T, compare: (a: T, b: T) => boolean) => {
  const previousRef = useRef<T>();
  const previous = previousRef.current;

  const isEqual = previous ? compare(previous, next) : false;

  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });

  return { isEqual, previous };
};

export const useMemoCompare = <T>(
  next: T,
  compare: (a: T, b: T) => boolean,
): T => {
  const { isEqual, previous } = usePrevious(next, compare);

  return isEqual ? previous ?? next : next;
};
