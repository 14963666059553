import { Configuration, PopupRequest } from "@azure/msal-browser";

const BASE_URL = `${window.location.protocol}//${window.location.host}`;
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID!!,
    authority: process.env.REACT_APP_MSAL_AUTHORITY!!,
    redirectUri: BASE_URL,
    postLogoutRedirectUri: BASE_URL,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker,
  },
};

export const backendTokenRequest: PopupRequest = {
  scopes: process.env.REACT_APP_BACKEND_SCOPES!!.split(";"),
  extraScopesToConsent: process.env.REACT_APP_BACKEND_EXTRA_SCOPES!!.split(";"),
  prompt: "select_account",
  domainHint: "pilargo.com",
};

export const graphTokenRequest: PopupRequest = {
  scopes: process.env.REACT_APP_GRAPH_SCOPES!!.split(";"),
};
