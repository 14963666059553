import { useMutation, useQueryClient } from "@tanstack/react-query";
import { backendApi } from "../../backend/backend-api";
import { CacheKeys } from "../../common-ui/cacheKeys";
import { FileDriveItem } from "../../backend/graph-api";
import { folderToInvoiceDirection } from "../utils";

export const useReanalyzeInvoiceMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (variables: {
      companyId: string;
      fileDriveItem: FileDriveItem;
    }) => {
      const { companyId, fileDriveItem } = variables;
      const direction = fileDriveItem.parent
        ? folderToInvoiceDirection(fileDriveItem.parent.name)
        : null;

      return direction && fileDriveItem.mimeType
        ? await backendApi.analyze(
            companyId,
            fileDriveItem.id,
            fileDriveItem.name,
            direction,
            fileDriveItem.mimeType,
            true,
          )
        : null;
    },
    onSuccess: async (analyzeResponse, variables) => {
      const fileDriveItem = variables.fileDriveItem;
      if (analyzeResponse && fileDriveItem.parent && fileDriveItem.mimeType) {
        const direction = folderToInvoiceDirection(fileDriveItem.parent.name);
        if (direction) {
          await queryClient.setQueryData(
            CacheKeys.forInvoiceAnalysis(
              fileDriveItem.id,
              direction,
              fileDriveItem.mimeType,
            ),
            analyzeResponse.invoiceAnalysis,
          );
        }
        onSuccess();
      }
    },
  });
};
