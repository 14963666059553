import { useQuery } from "@tanstack/react-query";
import { backendApi, InvoiceAnalysisDto } from "../../backend/backend-api";
import { CacheKeys } from "../../common-ui/cacheKeys";
import { FileDriveItem } from "../../backend/graph-api";
import { useMemo } from "react";
import { folderToInvoiceDirection } from "../utils";

export const useInvoiceAnalysis = (
  companyId: string,
  fileDriveItem: FileDriveItem,
) => {
  const direction = useMemo(
    () =>
      fileDriveItem.parent
        ? folderToInvoiceDirection(fileDriveItem.parent.name)
        : null,
    [fileDriveItem],
  );
  return useQuery({
    queryKey: CacheKeys.forInvoiceAnalysis(
      fileDriveItem.id,
      direction,
      fileDriveItem.mimeType,
    ),
    queryFn: async () => {
      return fileDriveItem.mimeType && direction
        ? (
            await backendApi.analyze(
              companyId,
              fileDriveItem.id,
              fileDriveItem.name,
              direction,
              fileDriveItem.mimeType,
            )
          ).invoiceAnalysis
        : Promise.resolve<InvoiceAnalysisDto>({});
    },
    refetchInterval: false,
    staleTime: Infinity,
  });
};
