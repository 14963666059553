import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export type GraphData = {
  displayName: string;
  jobTitle: string;
  mail: string;
  businessPhones: string[];
  officeLocation: string;
};

export const ProfileData = (props: { graphData: GraphData }) => {
  const { graphData } = props;
  return (
    <List>
      <NameListItem name={graphData.displayName} />
      <JobTitleListItem jobTitle={graphData.jobTitle} />
      <MailListItem mail={graphData.mail} />
      <LocationListItem location={graphData.officeLocation} />
    </List>
  );
};

const NameListItem = (props: { name: string }) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar>
        <PersonIcon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary="Name" secondary={props.name} />
  </ListItem>
);

const JobTitleListItem = (props: { jobTitle: string }) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar>
        <WorkIcon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary="Title" secondary={props.jobTitle} />
  </ListItem>
);

const MailListItem = (props: { mail: string }) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar>
        <MailIcon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary="Mail" secondary={props.mail} />
  </ListItem>
);

const LocationListItem = (props: { location: string }) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar>
        <LocationOnIcon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary="Location" secondary={props.location} />
  </ListItem>
);
