import React, { useState } from "react";
import { TextField, Button, Box, Chip } from "@mui/material";
import { Company } from "./types";

export const CompanyForm = (props: {
  company: Company;
  onCompanyChanged: (company: Company) => void;
}) => {
  const { company, onCompanyChanged } = props;
  const [aliasInput, setAliasInput] = useState("");

  const handleAddAlias = () => {
    if (aliasInput.trim() !== "") {
      onCompanyChanged({
        ...company,
        aliases: [...company.aliases, aliasInput.trim()],
      });
      setAliasInput("");
    }
  };

  const handleDeleteAlias = (aliasToDelete: string) => {
    onCompanyChanged({
      ...company,
      aliases: company.aliases.filter((x) => x !== aliasToDelete),
    });
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <TextField
        fullWidth
        margin="normal"
        label="Company Name"
        value={company.name}
        onChange={(e) => onCompanyChanged({ ...company, name: e.target.value })}
      />
      <TextField
        fullWidth
        margin="normal"
        label="VAT Number"
        value={company.vatNumber || ""}
        onChange={(e) =>
          onCompanyChanged({
            ...company,
            vatNumber: e.target.value ? e.target.value : null,
          })
        }
      />
      <TextField
        fullWidth
        margin="normal"
        label="Add Alias"
        value={aliasInput}
        onChange={(e) => setAliasInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleAddAlias();
          }
        }}
      />
      <Button
        variant="contained"
        onClick={handleAddAlias}
        sx={{ mt: 1, mb: 2 }}
      >
        Add Alias
      </Button>
      <Box>
        {company.aliases.map((alias, index) => (
          <Chip
            key={index}
            label={alias}
            onDelete={() => handleDeleteAlias(alias)}
            sx={{ mr: 1, mb: 1 }}
          />
        ))}
      </Box>
    </Box>
  );
};
