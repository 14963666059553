import { FileBrowser } from "../drive/FileBrowser";
import { AttachmentCarousel } from "../file-viewer/AttachmentCarousel";
import { useMemo, useState } from "react";
import { DriveItem, FileDriveItem } from "../backend/graph-api";
import { InvoiceEditor } from "../invoice/InvoiceEditor";
import { useFetchedChildDirectoryItems } from "../drive/useFetchedChildDirectoryItems";
import { FileItem } from "../file-viewer/types";
import { Panel, PanelGroup } from "react-resizable-panels";
import { useCompanyContext } from "../company/CompanyContext";
import { PanelSpinner } from "../common-ui/Spinner";
import { folderToInvoiceDirection } from "../invoice/utils";
import { PanelSeparator } from "../common-ui/PanelSeparator";

export const Main = () => {
  const [selectedDriveItems, setSelectedDriveItems] = useState<
    DriveItem[] | undefined
  >(undefined);
  const selectedSingleFile =
    selectedDriveItems &&
    selectedDriveItems.length === 1 &&
    selectedDriveItems[0].type === "file" &&
    selectedDriveItems[0].parent
      ? selectedDriveItems[0]
      : null;

  const { data: driveItems } = useFetchedChildDirectoryItems(
    selectedSingleFile && selectedSingleFile.parent
      ? selectedSingleFile.parent.id
      : null,
  );
  const fileItems = useMemo(
    (): FileItem[] =>
      driveItems
        ? driveItems
            .filter((x) => x.type === "file")
            .map((x) => x as FileDriveItem)
            .map(
              (x: FileDriveItem): FileItem => ({
                fileDriveItem: x,
              }),
            )
        : [],
    [driveItems],
  );

  const { isInitialized } = useCompanyContext();
  if (!isInitialized()) return <PanelSpinner />;

  return (
    <div className="main__container">
      <PanelGroup direction="horizontal">
        <Panel defaultSize={20} minSize={10} className="accounting-panel">
          <div style={{ overflow: "auto", width: "100%", height: "100%" }}>
            <FileBrowser
              selectedDriveItems={selectedDriveItems}
              onSelectDriveItems={(fileDriveItems) =>
                setSelectedDriveItems(fileDriveItems)
              }
            />
          </div>
        </Panel>
        <PanelSeparator />
        <Panel defaultSize={60} minSize={20} className="accounting-panel">
          {fileItems.length > 0 && selectedSingleFile && (
            <AttachmentCarousel
              attachments={fileItems}
              selectedIndex={Math.max(
                fileItems.findIndex(
                  (x) => x.fileDriveItem.id === selectedSingleFile.id,
                ),
                0,
              )}
              onSelectedFileItemChanged={(fileItem) =>
                setSelectedDriveItems(
                  fileItem?.fileDriveItem ? [fileItem.fileDriveItem] : [],
                )
              }
            />
          )}
        </Panel>
        <PanelSeparator />
        <Panel defaultSize={20} minSize={10} className="accounting-panel">
          <div className="main__invoice-editor">
            {selectedSingleFile &&
              selectedSingleFile.parent &&
              folderToInvoiceDirection(selectedSingleFile.parent.name) && (
                <InvoiceEditor
                  key={selectedSingleFile.id}
                  fileDriveItem={selectedSingleFile}
                />
              )}
          </div>
        </Panel>
      </PanelGroup>
    </div>
  );
};
