/* eslint-disable react/jsx-pascal-case */
import { useFetchedCompanies } from "./useFetchedCompanies";
import { useMemo, useState } from "react";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_EditActionButtons,
  useMaterialReactTable,
} from "material-react-table";
import { PanelSpinner } from "../common-ui/Spinner";
import { Company } from "./types";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { CompanyDialog } from "./CompanyDialog";

export const Companies = () => {
  const { data: companies } = useFetchedCompanies();
  const columns = useMemo<MRT_ColumnDef<Company>[]>(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        enableHiding: false,
      },
      {
        header: "VAT Number",
        accessorKey: "vatNumber",
      },
    ],
    [],
  );

  const companiesOrEmpty = companies ?? [];

  const [newCompany, setNewCompany] = useState<Company | null>(null);
  const [editingCompany, setEditingCompany] = useState<Company | null>(null);

  const table = useMaterialReactTable({
    columns,
    data: companiesOrEmpty,
    enableGlobalFilter: false,
    enablePagination: false,
    editDisplayMode: "modal",
    enableEditing: false,
    renderTopToolbarCustomActions: () => (
      <Button
        startIcon={<Add />}
        onClick={() =>
          setNewCompany({
            id: "",
            name: "",
            vatNumber: null,
            vatNumberVerified: null,
            aliases: [],
            rootFolderId: "",
          })
        }
      >
        New company
      </Button>
    ),
    renderCreateRowDialogContent: ({ table, row }) => (
      <>
        <DialogTitle variant="h3">Create New User</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        ></DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    onCreatingRowSave: () => {},
    renderEditRowDialogContent: ({ table, row }) => (
      <>
        <DialogTitle variant="h6">
          Edit company - {row.original.name}
        </DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        ></DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
  });

  if (!companies) return <PanelSpinner />;
  return (
    <>
      <Box>
        <MaterialReactTable table={table} />
      </Box>
      {newCompany && (
        <CompanyDialog
          company={newCompany}
          open={true}
          onClose={() => setNewCompany(null)}
        />
      )}
      {editingCompany && (
        <CompanyDialog
          company={editingCompany}
          open={true}
          onClose={() => setEditingCompany(null)}
        />
      )}
    </>
  );
};
