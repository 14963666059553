import {Box, Tab} from "@mui/material";
import {Companies} from "../company/Companies";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {useState} from "react";

const COMPANIES_TAB = "companies";

export const Settings = () => {
    const [tabValue, setTabValue] = useState(COMPANIES_TAB);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };
    return (
        <Box sx={{width: '100%', typography: 'body1'}}>
            <TabContext value={tabValue}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleChange}>
                        <Tab label="Companies" id="tab-companies" value={COMPANIES_TAB}/>
                    </TabList>
                </Box>
                <TabPanel value={COMPANIES_TAB}>
                    <Companies/>
                </TabPanel>
            </TabContext>
        </Box>
    );
};
