import { TreeItem, TreeView } from "@mui/x-tree-view";
import { useFetchedChildDirectoryItems } from "./useFetchedChildDirectoryItems";
import {
  DriveItem,
  FileDriveItem,
  FolderDriveItem,
} from "../backend/graph-api";
import {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Folder,
  FolderOpen,
  InsertDriveFileOutlined,
} from "@mui/icons-material";
import { MoveToSubFolderButton } from "../file-viewer/MoveToSubFolderButton";
import { ButtonGroup, CircularProgress } from "@mui/material";
import { useCurrentCompany } from "../company/CompanyContext";
import { RefreshButton } from "../file-viewer/RefreshButton";

const driveItemToNodeId = (driveItem: DriveItem) => JSON.stringify(driveItem);

const nodeIdToDriveItem = (nodeId: string): DriveItem =>
  JSON.parse(nodeId) as DriveItem;

export const MyTreeItem = (props: {
  selectedNodeIds: string[] | undefined;
  folderDriveItem: FolderDriveItem;
  onSelectDriveItems: (driveItems: DriveItem[]) => void;
}) => {
  const { selectedNodeIds, folderDriveItem, onSelectDriveItems } = props;
  const [childNodes, setChildNodes] = useState<ReactElement[] | null>(null);
  const [expanded, setExpanded] = useState<string[]>([]);

  const { data } = useFetchedChildDirectoryItems(folderDriveItem.id);

  const updateChildNodes = useCallback(
    (driveItems: DriveItem[]) =>
      setChildNodes(
        driveItems.length > 0
          ? driveItems
              .sort((x) =>
                x.type === "folder" ? 2 : x.type === "file" ? 1 : 0,
              )
              .map((x) =>
                x.type === "folder" ? (
                  <MyTreeItem
                    selectedNodeIds={selectedNodeIds}
                    key={x.id}
                    folderDriveItem={x}
                    onSelectDriveItems={onSelectDriveItems}
                  />
                ) : (
                  <TreeItem
                    key={x.id}
                    nodeId={driveItemToNodeId(x)}
                    label={x.name}
                    className="file-browser__item"
                    icon={<InsertDriveFileOutlined />}
                  />
                ),
              )
          : [
              <TreeItem
                key={"empty"}
                nodeId={"empty"}
                label={"the folder is empty"}
                className="file-browser__item"
              />,
            ],
      ),
    [onSelectDriveItems, selectedNodeIds],
  );

  useEffect(() => {
    if (data) updateChildNodes(data);
  }, [data, updateChildNodes]);

  const handleChange = async (_: SyntheticEvent, nodeIds: string[]) => {
    const expandingNodes = nodeIds.filter((x) => !expanded.includes(x));
    setExpanded(nodeIds);
    if (expandingNodes[0] && data) {
      updateChildNodes(data);
    }
  };

  return (
    <TreeView
      expanded={expanded}
      selected={selectedNodeIds}
      onNodeToggle={handleChange}
      onNodeSelect={(_, nodeIds) => {
        onSelectDriveItems(nodeIds.map(nodeIdToDriveItem));
      }}
      defaultCollapseIcon={<FolderOpen />}
      defaultExpandIcon={!data || data.length > 0 ? <Folder /> : <FolderOpen />}
      multiSelect={true}
    >
      <TreeItem
        nodeId={driveItemToNodeId(folderDriveItem)}
        label={folderDriveItem.name}
        className="file-browser__item"
      >
        {childNodes || [
          <CircularProgress
            size={"16px"}
            style={{ margin: "4px 0 4px 16px" }}
          />,
        ]}
      </TreeItem>
    </TreeView>
  );
};

export const FileBrowser = (props: {
  selectedDriveItems: DriveItem[] | undefined;
  onSelectDriveItems: (driveItem: DriveItem[]) => void;
}) => {
  const { currentCompany } = useCurrentCompany();
  const { selectedDriveItems, onSelectDriveItems } = props;
  const selectedNodeIds = selectedDriveItems
    ? selectedDriveItems.map(driveItemToNodeId)
    : undefined;
  return (
    <div>
      <ButtonGroup>
        {selectedDriveItems && (
          <RefreshButton driveItems={selectedDriveItems} />
        )}
        {selectedDriveItems &&
          selectedDriveItems.length > 0 &&
          selectedDriveItems.every(
            (x) =>
              x.type === "file" &&
              x.parent?.id &&
              x.parent?.id === selectedDriveItems[0].parent?.id,
          ) && (
            <MoveToSubFolderButton
              fileDriveItems={selectedDriveItems as FileDriveItem[]}
            />
          )}
      </ButtonGroup>
      <MyTreeItem
        key={currentCompany.id}
        selectedNodeIds={selectedNodeIds}
        folderDriveItem={{
          id: currentCompany.rootFolderId,
          name: currentCompany.name,
          type: "folder",
          childCount: 1,
          parent: null,
        }}
        onSelectDriveItems={onSelectDriveItems}
      />
    </div>
  );
};
