import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import React, { useState } from "react";
import { useFetchedChildDirectoryItems } from "../drive/useFetchedChildDirectoryItems";
import { FileDriveItem, graphApi } from "../backend/graph-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CacheKeys } from "../common-ui/cacheKeys";
import { DriveFileMove } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

export const MoveToSubFolderButton = (props: {
  fileDriveItems: FileDriveItem[];
}) => {
  const { fileDriveItems } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const { data: parentDirectoryItems } = useFetchedChildDirectoryItems(
    fileDriveItems[0].parent ? fileDriveItems[0].parent.id : null,
  );
  const queryClient = useQueryClient();
  const { mutate: moveFile } = useMutation({
    mutationFn: async (variables: { newParentId: string }) => {
      for (const x of fileDriveItems) {
        await graphApi.moveFile(x, variables.newParentId);
      }
    },
    onSettled: async (data, error, variables) => {
      if (fileDriveItems[0].parent !== null) {
        await queryClient.invalidateQueries({
          queryKey: CacheKeys.forDirectoryItems(fileDriveItems[0].parent.id),
        });
      }
      await queryClient.invalidateQueries({
        queryKey: CacheKeys.forDirectoryItems(variables.newParentId),
      });
    },
  });

  if (!parentDirectoryItems) return null;
  const subFolders = parentDirectoryItems.filter((x) => x.type === "folder");
  if (subFolders.length === 0) return null;
  return (
    <div>
      <IconButton onClick={onButtonClick}>
        <DriveFileMove />
      </IconButton>
      <Menu keepMounted open={open} onClose={onMenuClose} anchorEl={anchorEl}>
        {subFolders.map((x) => (
          <MenuItem
            onClick={() => {
              moveFile({ newParentId: x.id });
              setAnchorEl(null);
            }}
            key={x.id}
          >
            {x.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
