export const INVOICE_DIRECTION_INBOUND = "INBOUND";
export const INVOICE_DIRECTION_OUTBOUND = "OUTBOUND";
export type InvoiceDirection =
  | typeof INVOICE_DIRECTION_INBOUND
  | typeof INVOICE_DIRECTION_OUTBOUND;

export type Invoice = {
  direction: InvoiceDirection | null;
  date: Date | null;
  name: string | null;
  vatNumber: string | null;
  countryCode: string | null;
  isEU: boolean | null;
  invoiceNumber: string | null;
  currency: string | null;
  exRate: number | null;
  netAmount: number | null;
  vatAmount: number | null;
  transportationCost: number | null;
  total: number | null;
};
