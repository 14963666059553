import { msalInstance } from "../index";
import { backendTokenRequest, graphTokenRequest } from "../auth/authConfig";

export const callApi = async (
  destination: "backend" | "graph",
  method: string,
  url: string,
  responseFormat: "json" | "file" = "json",
  body?: unknown,
) => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called.",
    );
  }

  const tokenRequest =
    destination === "backend" ? backendTokenRequest : graphTokenRequest;
  const response = await msalInstance.acquireTokenSilent({
    ...tokenRequest,
    account: account,
  });

  const headers = new Headers();
  const bearer = `Bearer ${response.accessToken}`;

  headers.append("Authorization", bearer);
  if (body && destination === "graph") {
    headers.append("Content-Type", "application/json");
  }

  const options = {
    method: method,
    headers: headers,
    body: body ? JSON.stringify(body) : undefined,
  };

  return fetch(url, options)
    .then((response) => {
      return responseFormat === "json" ? response.json() : response.blob();
    })
    .catch((error) => console.log(error));
};
