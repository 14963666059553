import { useQuery } from "@tanstack/react-query";
import { graphApi } from "../backend/graph-api";
import { CacheKeys } from "../common-ui/cacheKeys";

export const useFetchedChildDirectoryItems = (itemId: string | null) => {
  return useQuery({
    queryKey: CacheKeys.forDirectoryItems(itemId ?? ""),
    queryFn: async () => (itemId ? graphApi.getChildDriveItems(itemId) : []),
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    staleTime: Infinity,
  });
};
