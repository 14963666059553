import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useCallback, useEffect, useState } from "react";
import { countries } from "./countries";
import { useInvoiceAnalysis } from "./hooks/useInvoiceAnalysis";
import { PanelSpinner } from "../common-ui/Spinner";
import dayjs from "dayjs";
import { Invoice } from "./types";
import Button from "@mui/material/Button";
import { useCreateInvoiceMutation } from "./hooks/useCreateInvoiceMutation";
import { Cached, ContentCopy } from "@mui/icons-material";
import { useReanalyzeInvoiceMutation } from "./hooks/useReanalyzeInvoiceMutation";
import { FileDriveItem } from "../backend/graph-api";
import { backendApi } from "../backend/backend-api";
import { useCurrentCompany } from "../company/CompanyContext";
import Grid2 from "@mui/material/Unstable_Grid2";
import { calculateInvoiceTotal } from "./utils";

export const EX_RATE_DIGITS = 6;

const formatAmount = (amount: number | null | undefined, decimals = 2) => {
  if (amount === undefined || amount === null) return "";
  return amount.toFixed(decimals);
};

export type InvoiceInput = {
  name: string;
  vatNumber: string;
  invoiceNumber: string;
  currency: string;
  exRate: string;
  netAmount: string;
  vatAmount: string;
  transportationCost: string;
};

const invoiceToInvoiceInput = (invoice: Invoice): InvoiceInput => ({
  name: invoice.name ?? "",
  vatNumber: invoice.vatNumber ?? "",
  invoiceNumber: invoice.invoiceNumber ?? "",
  currency: invoice.currency ?? "",
  exRate: formatAmount(invoice.exRate, EX_RATE_DIGITS),
  netAmount: formatAmount(invoice.netAmount),
  vatAmount: formatAmount(invoice.vatAmount),
  transportationCost: formatAmount(invoice.transportationCost),
});

export const InvoiceEditor = ({
  fileDriveItem,
}: {
  fileDriveItem: FileDriveItem;
}) => {
  const { currentCompany } = useCurrentCompany();
  const { data: invoiceAnalysis, status: invoiceAnalysisStatus } =
    useInvoiceAnalysis(currentCompany.id, fileDriveItem);
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [invoiceAnalysisTrigger, setInvoiceAnalysisTrigger] = useState(1);
  useEffect(() => {
    if (!invoiceAnalysis) return;
    setInvoice((prevInvoice) => ({
      direction: prevInvoice ? prevInvoice.direction : null,
      date: prevInvoice?.date
        ? prevInvoice.date
        : invoiceAnalysis.date?.value
          ? dayjs(new Date(invoiceAnalysis.date.value)).toDate()
          : null,
      name: prevInvoice?.name
        ? prevInvoice.name
        : invoiceAnalysis.name?.value ?? null,
      vatNumber: prevInvoice?.vatNumber
        ? prevInvoice.vatNumber
        : invoiceAnalysis.vatNumber?.value ?? null,
      countryCode: prevInvoice?.countryCode
        ? prevInvoice.countryCode
        : invoiceAnalysis.countryCode?.value ?? null,
      isEU:
        prevInvoice?.isEU !== null && prevInvoice?.isEU !== undefined
          ? prevInvoice.isEU
          : invoiceAnalysis.isEU
            ? invoiceAnalysis.isEU.value
            : null,
      invoiceNumber: prevInvoice?.invoiceNumber
        ? prevInvoice.invoiceNumber
        : invoiceAnalysis.invoiceNumber?.value ?? null,
      currency: prevInvoice?.currency
        ? prevInvoice.currency
        : invoiceAnalysis.currency?.value ?? null,
      exRate: prevInvoice?.exRate
        ? prevInvoice.exRate
        : invoiceAnalysis.exRate
          ? parseFloat(invoiceAnalysis.exRate.value)
          : null,
      netAmount: prevInvoice?.netAmount
        ? prevInvoice.netAmount
        : invoiceAnalysis.netAmount
          ? parseFloat(invoiceAnalysis.netAmount.value)
          : null,
      vatAmount: prevInvoice?.vatAmount
        ? prevInvoice.vatAmount
        : invoiceAnalysis.vatAmount
          ? parseFloat(invoiceAnalysis.vatAmount.value)
          : null,
      transportationCost: prevInvoice?.transportationCost
        ? prevInvoice.transportationCost
        : invoiceAnalysis.transportationCost
          ? parseFloat(invoiceAnalysis.transportationCost.value)
          : null,
      total: prevInvoice?.total
        ? prevInvoice.total
        : invoiceAnalysis.total
          ? parseFloat(invoiceAnalysis.total.value)
          : null,
    }));
  }, [invoiceAnalysis, invoiceAnalysisTrigger]);

  const [invoiceInput, setInvoiceInput] = useState<InvoiceInput | null>(
    invoice ? invoiceToInvoiceInput(invoice) : null,
  );
  useEffect(() => {
    if (invoiceInput || !invoice) return;
    setInvoiceInput(invoiceToInvoiceInput(invoice));
  }, [invoice, invoiceInput]);

  const { mutate: createInvoice, status: createInvoiceStatus } =
    useCreateInvoiceMutation();

  const onReanalyzeInvoiceSuccess = useCallback(() => {
    setInvoice(null);
    setInvoiceInput(null);
    setInvoiceAnalysisTrigger((prevState) => prevState + 1);
  }, []);
  const { mutate: reanalyzeInvoice, status: reanalyzeInvoiceStatus } =
    useReanalyzeInvoiceMutation(onReanalyzeInvoiceSuccess);

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      const currency = invoice?.currency;
      const date = invoice?.date;
      if (!currency || !currency.match(/^[A-Z]{3}$/) || !date) return;
      const exRate = (await backendApi.getExRate(currency, date)).exRate.value;
      if (isSubscribed) {
        setInvoice((prevState) =>
          prevState ? { ...prevState, exRate: parseFloat(exRate) } : null,
        );
        setInvoiceInput((prevState) =>
          prevState ? { ...prevState, exRate } : null,
        );
      }
    };
    fetchData().catch(console.error);
    return () => {
      isSubscribed = false;
    };
  }, [invoice?.currency, invoice?.date]);

  if (invoiceAnalysisStatus === "pending" || !invoice || !invoiceInput)
    return <PanelSpinner />;
  if (invoiceAnalysisStatus === "error" || !invoiceAnalysis)
    return <div>Failed to analyze the invoice.</div>;
  return (
    <Grid2
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={2}
      width="100%"
    >
      <Grid2 xs={12}>
        <Button
          disabled={reanalyzeInvoiceStatus === "pending"}
          onClick={async () => {
            await reanalyzeInvoice({
              companyId: currentCompany.id,
              fileDriveItem,
            });
          }}
        >
          <Cached /> Reanalyze
        </Button>
      </Grid2>
      <Grid2>
        <DatePicker
          label="Invoice date"
          format="DD/MM/YYYY"
          onChange={async (value) => {
            setInvoice((prevState) =>
              prevState
                ? { ...prevState, date: value?.toDate() ?? null }
                : null,
            );
          }}
          value={invoice.date ? dayjs(new Date(invoice.date)) : undefined}
        />
      </Grid2>
      <Grid2>
        <TextField
          id="company-name-field"
          label="Company"
          autoComplete="off"
          fullWidth
          onBlur={() => {
            setInvoiceInput(invoiceToInvoiceInput(invoice));
          }}
          onChange={(e) => {
            setInvoice((prevState) =>
              prevState ? { ...prevState, name: e.target.value.trim() } : null,
            );
            setInvoiceInput((prevState) =>
              prevState ? { ...prevState, name: e.target.value } : null,
            );
          }}
          value={invoiceInput.name}
        />
      </Grid2>
      <Grid2>
        <TextField
          id="vat-number-field"
          label="VAT number"
          autoComplete="off"
          fullWidth
          onBlur={() => {
            setInvoiceInput(invoiceToInvoiceInput(invoice));
          }}
          onChange={(e) => {
            setInvoice((prevState) =>
              prevState
                ? { ...prevState, vatNumber: e.target.value.trim() }
                : null,
            );
            setInvoiceInput((prevState) =>
              prevState ? { ...prevState, vatNumber: e.target.value } : null,
            );
          }}
          value={invoiceInput.vatNumber}
        />
      </Grid2>
      <Grid2>
        <TextField
          id="invoice-number-field"
          label="Invoice number"
          fullWidth
          autoComplete="off"
          onBlur={() => {
            setInvoiceInput(invoiceToInvoiceInput(invoice));
          }}
          onChange={(e) => {
            setInvoice((prevState) =>
              prevState
                ? { ...prevState, invoiceNumber: e.target.value.trim() }
                : null,
            );
            setInvoiceInput((prevState) =>
              prevState
                ? { ...prevState, invoiceNumber: e.target.value }
                : null,
            );
          }}
          value={invoiceInput.invoiceNumber}
        />
      </Grid2>
      <Grid2>
        <Autocomplete
          id="country-field"
          fullWidth
          autoHighlight={true}
          autoSelect={true}
          value={countries.find((x) => x.code === invoice?.countryCode)}
          getOptionLabel={(option) => `${option.label} (${option.code})`}
          options={countries}
          onChange={(e, country) =>
            setInvoice((prevState) =>
              prevState
                ? { ...prevState, countryCode: country ? country.code : null }
                : null,
            )
          }
          renderInput={(params) => (
            <TextField {...params} fullWidth label="Choose a country" />
          )}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                alt=""
              />
              {option.label} ({option.code})
            </Box>
          )}
        />
      </Grid2>
      <Grid2>
        <FormControlLabel
          control={
            <Checkbox
              id="is-eu-field"
              onChange={(e) =>
                setInvoice((prevState) =>
                  prevState ? { ...prevState, isEU: e.target.checked } : null,
                )
              }
              checked={invoice?.isEU ?? false}
            />
          }
          label="Is EU?"
        />
      </Grid2>
      <Grid2>
        <TextField
          id="currency-field"
          label="Currency"
          autoComplete="off"
          fullWidth
          onBlur={() => {
            setInvoiceInput(invoiceToInvoiceInput(invoice));
          }}
          onChange={(e) => {
            setInvoice((prevState) =>
              prevState
                ? { ...prevState, currency: e.target.value.trim() }
                : null,
            );
            setInvoiceInput((prevState) =>
              prevState ? { ...prevState, currency: e.target.value } : null,
            );
          }}
          value={invoiceInput.currency}
        />
      </Grid2>
      <Grid2>
        <TextField
          id="ex-rate-field"
          label="Ex rate"
          autoComplete="off"
          fullWidth
          onBlur={() => {
            setInvoiceInput(invoiceToInvoiceInput(invoice));
          }}
          onChange={(e) => {
            setInvoice((prevState) =>
              prevState
                ? { ...prevState, exRate: parseFloat(e.target.value.trim()) }
                : null,
            );
            setInvoiceInput((prevState) =>
              prevState ? { ...prevState, exRate: e.target.value } : null,
            );
          }}
          value={invoiceInput.exRate}
        />
      </Grid2>
      <Grid2>
        <TextField
          id="net-amount-field"
          label="Net amount"
          autoComplete="off"
          fullWidth
          type="number"
          InputProps={{
            inputProps: {
              step: "any",
            },
          }}
          onBlur={() => {
            setInvoiceInput(invoiceToInvoiceInput(invoice));
          }}
          onChange={(e) => {
            setInvoice((prevState) =>
              prevState
                ? { ...prevState, netAmount: parseFloat(e.target.value) }
                : null,
            );
            setInvoiceInput((prevState) =>
              prevState ? { ...prevState, netAmount: e.target.value } : null,
            );
          }}
          value={invoiceInput.netAmount}
        />
      </Grid2>
      <Grid2>
        <TextField
          id="vat-amount-field"
          label="VAT"
          autoComplete="off"
          fullWidth
          type="number"
          InputProps={{
            inputProps: {
              step: "any",
            },
          }}
          onBlur={() => {
            setInvoiceInput(invoiceToInvoiceInput(invoice));
          }}
          onChange={(e) => {
            setInvoice((prevState) =>
              prevState
                ? { ...prevState, vatAmount: parseFloat(e.target.value) }
                : null,
            );
            setInvoiceInput((prevState) =>
              prevState ? { ...prevState, vatAmount: e.target.value } : null,
            );
          }}
          value={invoiceInput.vatAmount}
        />
      </Grid2>
      <Grid2>
        <TextField
          id="transportation-cost-field"
          label="Transportation costs"
          autoComplete="off"
          fullWidth
          type="number"
          InputProps={{
            inputProps: {
              step: "any",
            },
          }}
          onBlur={() => {
            setInvoiceInput(invoiceToInvoiceInput(invoice));
          }}
          onChange={(e) => {
            setInvoice((prevState) => {
              let newTransportationCost = parseFloat(e.target.value);
              return prevState
                ? {
                    ...prevState,
                    transportationCost: newTransportationCost,
                  }
                : null;
            });
            setInvoiceInput((prevState) =>
              prevState
                ? {
                    ...prevState,
                    transportationCost: e.target.value,
                  }
                : null,
            );
          }}
          value={invoiceInput.transportationCost}
        />
      </Grid2>
      <Grid2>
        <TextField
          id="total-field"
          label="Total"
          autoComplete="off"
          fullWidth
          type="number"
          InputProps={{
            inputProps: {
              step: "any",
            },
            readOnly: true,
          }}
          variant="filled"
          value={formatAmount(
            calculateInvoiceTotal(
              invoice.netAmount,
              invoice.vatAmount,
              invoice.transportationCost,
            ),
          )}
        />
      </Grid2>
      <Grid2>
        <Button
          disabled={createInvoiceStatus === "pending"}
          onClick={async () =>
            await createInvoice({ companyId: currentCompany.id, invoice })
          }
        >
          <ContentCopy /> for Excel
        </Button>
      </Grid2>
    </Grid2>
  );
};
