import { Invoice } from "../invoice/types";
import { InvoiceDto } from "./backend-api";
import { EX_RATE_DIGITS } from "../invoice/InvoiceEditor";

function serializeAmount(amount: number | null, digits = 2) {
  return amount !== null ? amount?.toFixed(digits) : null;
}

export const invoiceToDto = (invoice: Invoice): InvoiceDto => ({
  ...invoice,
  exRate: serializeAmount(invoice.exRate, EX_RATE_DIGITS),
  netAmount: serializeAmount(invoice.netAmount),
  vatAmount: serializeAmount(invoice.vatAmount),
  transportationCost: serializeAmount(invoice.transportationCost),
  total: serializeAmount(invoice.total),
});
