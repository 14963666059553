import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AttachmentCarouselHeader } from "./AttachmentCarouselHeader";
import {
  AttachmentCarouselBody,
  IndexedProgramAttachment,
} from "./AttachmentCarouselBody";
import { FileItem } from "./types";
import _ from "lodash";

const adjustIndex = (index: number, count: number) =>
  Math.min(Math.max(index, 0), count - 1);

export const AttachmentCarousel = (props: {
  attachments: FileItem[];
  selectedIndex: number;
  onSelectedFileItemChanged: (selectedFileItem: FileItem | null) => void;
}) => {
  const { attachments, selectedIndex, onSelectedFileItemChanged } = props;

  const [currentAttachment, setCurrentAttachment] =
    useState<IndexedProgramAttachment>({
      attachment: attachments[selectedIndex],
      index: selectedIndex,
    });
  useEffect(() => {
    const newIndex = adjustIndex(currentAttachment.index, attachments.length);
    const newCurrentAttachment = {
      attachment: attachments[newIndex],
      index: newIndex,
    };
    if (_.isEqual(newCurrentAttachment, currentAttachment)) return;
    setCurrentAttachment(newCurrentAttachment);
  }, [attachments, currentAttachment]);
  useEffect(() => {
    setCurrentAttachment({
      attachment: attachments[selectedIndex],
      index: selectedIndex,
    });
  }, [attachments, selectedIndex]);

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!containerRef.current) return;
    containerRef.current.focus();
  }, []);

  const onPrevClick = useCallback(() => {
    const newIndex = adjustIndex(
      currentAttachment.index - 1,
      attachments.length,
    );
    if (newIndex === currentAttachment.index) return;
    if (newIndex === 0) containerRef.current?.focus();
    let newCurrentAttachment = attachments[newIndex];
    setCurrentAttachment({
      attachment: newCurrentAttachment,
      index: newIndex,
    });
    onSelectedFileItemChanged(newCurrentAttachment);
  }, [
    attachments,
    currentAttachment,
    setCurrentAttachment,
    onSelectedFileItemChanged,
  ]);

  const onNextClick = useCallback(() => {
    const newIndex = adjustIndex(
      currentAttachment.index + 1,
      attachments.length,
    );
    if (newIndex === currentAttachment.index) return;
    if (newIndex === attachments.length - 1) containerRef.current?.focus();
    let newCurrentAttachment = attachments[newIndex];
    setCurrentAttachment({
      attachment: attachments[newIndex],
      index: newIndex,
    });
    onSelectedFileItemChanged(newCurrentAttachment);
  }, [
    attachments,
    currentAttachment,
    setCurrentAttachment,
    onSelectedFileItemChanged,
  ]);

  const onContainerKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.altKey || e.ctrlKey || e.shiftKey || e.metaKey) return;
      if (e.key === "ArrowLeft") onPrevClick();
      else if (e.key === "ArrowRight") onNextClick();
    },
    [onPrevClick, onNextClick],
  );

  const body = useMemo(
    () => (
      <AttachmentCarouselBody
        attachments={attachments}
        currentAttachment={currentAttachment}
      />
    ),
    [attachments, currentAttachment],
  );

  return (
    <div
      className="attachments-view__container"
      onKeyDown={onContainerKeyDown}
      tabIndex={-1}
      ref={containerRef}
    >
      <AttachmentCarouselHeader
        currentAttachment={currentAttachment.attachment}
        prevDisabled={currentAttachment.index <= 0}
        nextDisabled={currentAttachment.index >= attachments.length - 1}
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
      />
      {body}
    </div>
  );
};
