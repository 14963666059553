import React, { useCallback } from "react";
import prettyBytes from "pretty-bytes";
import { FileItem } from "./types";
import Button from "@mui/material/Button";
import { ChevronLeft, ChevronRight, Download } from "@mui/icons-material";
import { useAttachmentContent } from "./hooks/useAttachmentsContent";
import { downloadFile } from "./file-utils";
import { Typography } from "@mui/material";

export const AttachmentCarouselHeader = (props: {
  currentAttachment: FileItem;
  prevDisabled: boolean;
  nextDisabled: boolean;
  onPrevClick: () => void;
  onNextClick: () => void;
}) => {
  const {
    currentAttachment,
    prevDisabled,
    nextDisabled,
    onPrevClick,
    onNextClick,
  } = props;

  const { data: attachmentWithContent } =
    useAttachmentContent(currentAttachment);
  const fileSizeString =
    attachmentWithContent && attachmentWithContent.file
      ? `(${prettyBytes(attachmentWithContent.file.size)})`
      : "";

  const download = useCallback(() => {
    if (
      !attachmentWithContent?.url ||
      !attachmentWithContent?.fileDriveItem.name
    )
      return;

    downloadFile(
      attachmentWithContent.url,
      attachmentWithContent.fileDriveItem.name,
    );
  }, [attachmentWithContent?.url, attachmentWithContent?.fileDriveItem.name]);
  return (
    <div className="attachments-view__header-container">
      <div className="attachments-view__file-details">
        <Typography noWrap={true}>
          {currentAttachment.fileDriveItem.name}
        </Typography>
        {!!fileSizeString && (
          <div
            className="attachments-view__file-size"
            data-test="attachments-view__file-size"
          >
            &nbsp;{fileSizeString}
          </div>
        )}
      </div>
      {currentAttachment.fileDriveItem.name && (
        <Button
          className="attachments-view__header-button"
          data-test="attachments-view__download-button"
          onClick={download}
          disabled={!attachmentWithContent?.url}
        >
          <Download className="attachments-view__icon" />
        </Button>
      )}
      <Button
        className="attachments-view__navigation-button"
        data-test="attachments-view__prev-button"
        onClick={onPrevClick}
        disabled={prevDisabled}
      >
        <ChevronLeft />
      </Button>
      <Button
        className="attachments-view__navigation-button"
        data-test="attachments-view__next-button"
        onClick={onNextClick}
        disabled={nextDisabled}
      >
        <ChevronRight />
      </Button>
    </div>
  );
};
