import React from "react";
import { AttachmentMessage } from "./AttachmentMessage";

export const FailedToDownloadAttachmentMessage = (props: {
  fileName: string;
  refetch: () => void;
}) => {
  const { fileName, refetch } = props;

  return (
    <AttachmentMessage
      fileName={fileName}
      buttonText="Try again"
      message="Attachment could not be downloaded"
      onButtonClick={() => refetch()}
      dataTestPrefix="attachment-view__failed-to-download"
      isError={true}
    />
  );
};
