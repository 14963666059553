import { useQueries } from "@tanstack/react-query";
import { FileItem } from "../types";
import { getFileExtension } from "../file-utils";
import { graphApi } from "../../backend/graph-api";
import { CacheKeys } from "../../common-ui/cacheKeys";

export type ProgramAttachmentWithUrl = FileItem & {
  url?: string;
};
const extensionToMimeType = (fileExtension: string): string => {
  switch (fileExtension) {
    case "bmp":
      return "image/bmp";
    case "csv":
      return "text/csv";
    case "gif":
      return "image/gif";
    case "htm":
      return "text/htm";
    case "html":
      return "text/html";
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "pdf":
      return "application/pdf";
    case "png":
      return "image/png";
    case "tiff":
    case "tif":
      return "image/tiff";
    case "txt":
      return "text/plain";
    default:
      return "application/octet-stream";
  }
};

const getOrDownloadFile = async (fileItemId: string, attachment: FileItem) => {
  const filename = attachment.fileDriveItem.name;
  const fileExtension = getFileExtension(filename);
  return attachment.file
    ? Promise.resolve(attachment.file)
    : graphApi.downloadFile(fileItemId).then((res) => {
        return new File([res], filename, {
          type: extensionToMimeType(fileExtension),
        });
      });
};

export const createAttachmentContentQueryParams = (
  queryKey: string[],
  fileItem: FileItem,
  isEnabled: boolean,
) => {
  return {
    enabled: isEnabled,
    queryKey: queryKey,
    queryFn: async (): Promise<ProgramAttachmentWithUrl> => {
      const file = await getOrDownloadFile(fileItem.fileDriveItem.id, fileItem);
      const url = window.URL.createObjectURL(file);
      return { ...fileItem, file, url };
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchInterval: 0,
  };
};

export const useAttachmentsContent = (
  attachments: FileItem[],
  currentIndex: number,
) => {
  return useQueries({
    queries: attachments.map((attachment, index) =>
      createAttachmentContentQueryParams(
        CacheKeys.forFileItemContent(attachment.fileDriveItem.id),
        attachment,
        index === currentIndex,
      ),
    ),
  });
};

export const useAttachmentContent = (attachment: FileItem) =>
  useAttachmentsContent([attachment], 0)[0];
