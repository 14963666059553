import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import { LoginLogoutButton } from "../auth/LoginLogoutButton";
import logoImage from "../assets/images/logo.png";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { useCompanyContext } from "../company/CompanyContext";
import { CompanyDto } from "../backend/backend-api";
import { ListItemButton, Modal } from "@mui/material";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import { useFetchedCompanies } from "../company/useFetchedCompanies";
import ListItemText from "@mui/material/ListItemText";

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const { getCurrentCompany, isInitialized, setCurrentCompany } =
    useCompanyContext();

  const { data: companies } = useFetchedCompanies();
  const handleOpen = () => companies && setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSelectRealm = (company: CompanyDto) => {
    setCurrentCompany(company);
    handleClose();
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography style={{ flexGrow: 1 }}>
            <Link
              component={RouterLink}
              to="/"
              color="inherit"
              variant="h6"
              style={{
                textDecoration: "none",
                display: "flex",
                columnGap: 16,
                alignItems: "center",
              }}
            >
              <span
                style={{
                  padding: "16px 4px 0 4px",
                  margin: "-16px 0 0 0",
                  backgroundColor: "white",
                  borderBottomRightRadius: "16px",
                  borderBottomLeftRadius: "16px",
                }}
              >
                <img src={logoImage} height={32} width={32} alt="Pilargo" />
              </span>
              Pilargo Books
            </Link>
          </Typography>
          {isInitialized() && (
            <Button color="inherit" onClick={handleOpen}>
              {getCurrentCompany()?.name}
            </Button>
          )}
          <LoginLogoutButton />
        </Toolbar>
      </AppBar>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="realm-switcher-modal-title"
        aria-describedby="realm-switcher-modal-description"
      >
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            padding: 4,
          }}
        >
          <Typography variant="h6" id="realm-switcher-modal-title" gutterBottom>
            Select a company
          </Typography>
          <List>
            {companies &&
              companies.map((x) => (
                <ListItemButton key={x.id} onClick={() => handleSelectRealm(x)}>
                  <ListItemText primary={x.name} />
                </ListItemButton>
              ))}
          </List>
        </Paper>
      </Modal>
    </>
  );
};

export default NavBar;
