import { callApi } from "./common";
import { appConfig } from "../config";
import { Invoice, InvoiceDirection } from "../invoice/types";
import { invoiceToDto } from "./util";
import dayjs from "dayjs";
import { Company } from "../company/types";

export type AnalyzeResponse = {
  invoiceAnalysis: InvoiceAnalysisDto;
};

export type CreateInvoiceRequest = {
  invoice: InvoiceDto;
};

export type CreateInvoiceResponse = {
  csvLine: string;
};

export type InvoiceDto = {
  date: Date | null;
  name: string | null;
  vatNumber: string | null;
  invoiceNumber: string | null;
  countryCode: string | null;
  isEU: boolean | null;
  currency: string | null;
  exRate: string | null;
  netAmount: string | null;
  vatAmount: string | null;
  transportationCost: string | null;
  total: string | null;
};

export type InvoiceAnalysisDto = {
  date?: InvoiceFieldDto<string> | null;
  name?: InvoiceFieldDto<string> | null;
  vatNumber?: InvoiceFieldDto<string> | null;
  countryCode?: InvoiceFieldDto<string> | null;
  isEU?: InvoiceFieldDto<boolean> | null;
  invoiceNumber?: InvoiceFieldDto<string> | null;
  currency?: InvoiceFieldDto<string> | null;
  exRate?: InvoiceFieldDto<string> | null;
  netAmount?: InvoiceFieldDto<string> | null;
  vatAmount?: InvoiceFieldDto<string> | null;
  transportationCost?: InvoiceFieldDto<string> | null;
  total?: InvoiceFieldDto<string> | null;
  error?: string | null;
};

export type InvoiceFieldDto<T> = {
  value: T;
  textValue: string;
  normalizedValue: string;
  confidence: number;
  pageRefs: PageRefDto[];
};

export type PageRefDto = {
  page: number;
  vertices: VertexDto[];
};

export type VertexDto = {
  x: number;
  y: number;
};

const supportedMimeTypes = new Set([
  "application/pdf",
  "image/gif",
  "image/tiff",
  "image/jpeg",
  "image/png",
  "image/bmp",
  "image/webp",
  "text/html",
]);

export type ExRateDto = {
  date: Date;
  value: string;
};

export type GetExRateResponse = {
  exRate: ExRateDto;
};

export type CompanyDto = {
  id: string;
  name: string;
  aliases: string[];
  vatNumber: string | null;
  vatNumberVerified: boolean | null;
  rootFolderId: string;
};

export type GetCompaniesResponse = {
  companies: CompanyDto[];
};

export type CreateCompanyRequest = {
  name: string;
  vatNumber: string | null;
  aliases: string[];
};

export type CreateCompanyResponse = {
  company: CompanyDto;
};

export type UpdateCompanyRequest = {
  company: Company;
};

export type UpdateCompanyResponse = {
  company: Company;
};

const ISO_DATE_FORMAT = "YYYY-MM-DD";

export const backendApi = {
  getCompanies: async (): Promise<GetCompaniesResponse> => {
    return (await callApi(
      "backend",
      "GET",
      `${appConfig.backendApiBaseUrl}/companies`,
      "json",
    )) as Promise<GetCompaniesResponse>;
  },

  createCompany: async (
    request: CreateCompanyRequest,
  ): Promise<CreateCompanyResponse> =>
    (await callApi(
      "backend",
      "POST",
      `${appConfig.backendApiBaseUrl}/companies`,
      "json",
      request,
    )) as Promise<CreateCompanyResponse>,

  updateCompany: async (
    request: UpdateCompanyRequest,
  ): Promise<CreateCompanyResponse> =>
    (await callApi(
      "backend",
      "PUT",
      `${appConfig.backendApiBaseUrl}/companies/${request.company.id}`,
      "json",
      request,
    )) as Promise<UpdateCompanyResponse>,

  analyze: async (
    companyId: string,
    fileItemId: string,
    fileName: string,
    direction: InvoiceDirection,
    mimeType: string,
    reanalyze?: boolean,
  ): Promise<AnalyzeResponse> =>
    supportedMimeTypes.has(mimeType)
      ? ((await callApi(
          "backend",
          "POST",
          `${appConfig.backendApiBaseUrl}/companies/${companyId}/analyze`,
          "json",
          { fileItemId, fileName, direction, mimeType, reanalyze },
        )) as Promise<AnalyzeResponse>)
      : Promise.resolve<AnalyzeResponse>({ invoiceAnalysis: {} }),

  createInvoice: async (
    companyId: string,
    invoice: Invoice,
  ): Promise<CreateInvoiceResponse> => {
    const request: CreateInvoiceRequest = {
      invoice: invoiceToDto(invoice),
    };
    return (await callApi(
      "backend",
      "POST",
      `${appConfig.backendApiBaseUrl}/companies/${companyId}/invoices`,
      "json",
      request,
    )) as Promise<CreateInvoiceResponse>;
  },

  getExRate: async (
    currency: string,
    date: Date,
  ): Promise<GetExRateResponse> => {
    return (await callApi(
      "backend",
      "GET",
      `${appConfig.backendApiBaseUrl}/ex-rates/${currency}/${dayjs(date).format(ISO_DATE_FORMAT)}`,
      "json",
    )) as Promise<GetExRateResponse>;
  },
};
