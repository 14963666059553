import { CircularProgress } from "@mui/material";
import styled from "styled-components";

const Spinner = (props: { size: "big" | "small" }) => {
  const { size } = props;
  return (
    <div
      className={size === "big" ? "growegy-spinner--big" : "growegy-spinner"}
      role="status"
    ></div>
  );
};

export const InlineSpinner = () => <Spinner size="small" />;
export const FullScreenSpinner = () => (
  <div className="growegy-spinner_container--full-screen">
    <Spinner size="big" />
  </div>
);

const PanelSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const PanelSpinner = () => (
  <PanelSpinnerContainer>
    <CircularProgress />
  </PanelSpinnerContainer>
);
