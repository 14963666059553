import React from "react";
import { useFetchedChildDirectoryItems } from "../drive/useFetchedChildDirectoryItems";
import { DriveItem } from "../backend/graph-api";
import { RefreshRounded } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

export const RefreshButton = (props: { driveItems: DriveItem[] }) => {
  const { driveItems } = props;

  const driveItemToRefresh: DriveItem | null =
    driveItems.length !== 1
      ? null
      : driveItems[0].type === "file" && driveItems[0].parent
        ? driveItems[0].parent
        : driveItems[0].type === "folder"
          ? driveItems[0]
          : null;
  const { refetch, fetchStatus } = useFetchedChildDirectoryItems(
    driveItemToRefresh ? driveItemToRefresh.id : null,
  );

  if (!driveItemToRefresh) return null;
  return (
    <IconButton onClick={() => refetch()} disabled={fetchStatus === "fetching"}>
      <RefreshRounded />
    </IconButton>
  );
};
