import { useMutation } from "@tanstack/react-query";
import { backendApi } from "../../backend/backend-api";
import { Invoice } from "../types";
import copy from "copy-to-clipboard";

export const useCreateInvoiceMutation = () => {
  return useMutation({
    mutationFn: (variables: { companyId: string; invoice: Invoice }) =>
      backendApi.createInvoice(variables.companyId, variables.invoice),
    onSuccess: (data) => copy(data.csvLine),
  });
};
