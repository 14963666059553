import React from "react";
import "./App.scss";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { PageLayout } from "./common-ui/PageLayout";
import { Pages } from "./Pages";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { CompanyProvider } from "./company/CompanyContext";

function App(props: { pca: PublicClientApplication }) {
  const { pca } = props;
  return (
    <MsalProvider instance={pca}>
      <CompanyProvider>
        <PageLayout>
          <Pages />
          <ReactQueryDevtools initialIsOpen={false} />
        </PageLayout>
      </CompanyProvider>
    </MsalProvider>
  );
}

export default App;
