import { useMutation, useQueryClient } from "@tanstack/react-query";
import { backendApi, UpdateCompanyRequest } from "../../backend/backend-api";
import { CacheKeys } from "../../common-ui/cacheKeys";

export const useUpdateCompanyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: UpdateCompanyRequest) =>
      backendApi.updateCompany(variables),
    onSuccess: async () =>
      await queryClient.invalidateQueries({ queryKey: CacheKeys.companies }),
  });
};
