import { useQuery } from "@tanstack/react-query";
import { CacheKeys } from "../common-ui/cacheKeys";
import { backendApi } from "../backend/backend-api";
import _ from "lodash";
import {Company} from "./types";

export const useFetchedCompanies = () => {
  return useQuery({
    queryKey: CacheKeys.companies,
    queryFn: async (): Promise<Company[]> =>
      _((await backendApi.getCompanies()).companies)
        .sortBy((x) => x.name)
        .value(),
    refetchInterval: 240_000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    staleTime: 120_000,
  });
};
