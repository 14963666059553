import React, { useState } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Divider, ListItemIcon, ListItemText } from "@mui/material";
import { Logout, Person, Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export const GlobalMenu = () => {
  const { instance } = useMsal();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLogout = () => {
    setAnchorEl(null);

    instance.logout({
      postLogoutRedirectUri: "/",
      account: instance.getActiveAccount(),
    });
  };
  const navigate = useNavigate();

  return (
    <div>
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            navigate("profile");
          }}
          key="profile"
        >
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText>{account?.name ?? "Profile"}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            navigate("settings");
          }}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleLogout()} key="logoutPopup">
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};
